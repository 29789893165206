import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";

import { withContext } from "~context";
import { SectionLayout } from "~components/templates/section-layout";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { Button } from "~components/utils/button";
import { Interview } from "~components/utils/interview";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { FaqStyle } from "~components/templates/faq-style";
import { TableStyle } from "~components/templates/table-style";
import { DocumentStyle } from "~components/templates/document-style";

type LPProps = {
  data: GatsbyAssessmentWebAppPageQuery;
};

const LP: React.FC<LPProps> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "Webアプリケーション診断",
          description:
            "ブラウザで閲覧・利用するWebアプリケーションに関してXSS（クロスサイトスクリプティング）やSQLインジェクションのような脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。",
        }}
      >
        <div className="WebApp AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop WebAppTop">
              <div className="AssessmentCommonTop_Wrapper">
                <SectionLayout>
                  <h2 className="AssessmentCommonTop_Title">
                    Webアプリケーション診断
                  </h2>
                  <div className="AssessmentCommonTop_Body">
                    ブラウザで閲覧・利用するWebアプリケーションに関してXSS（クロスサイトスクリプティング）やSQLインジェクションのような脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。
                  </div>
                </SectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="Webアプリケーション診断" />
          <LazyLoad>
            <Section background="light-gray">
              <Common2PaneSectionLayout width="no-limit">
                <h2 className="Feature_Title">Flatt Securityの診断の特徴</h2>
                <div className="Feature_ContentWrapper">
                  <div className="Feature_Content No1">
                    <h2 className="Feature_ContentTitle">
                      実績あるエンジニア
                      <br className="Feature_Br" />
                      だからこそできる手動診断
                    </h2>
                    <p className="Feature_ContentText">
                      Flatt Security
                      では高度なセキュリティの技術を持ったエンジニアが、新たな脆弱性の発見や攻撃手法についての研究を行っています。また、弊社の社外取締役であり
                      *OWASP JAPAN
                      代表でもある上野宣によるアドバイスのもと脆弱性診断を設計しています。
                      <br />
                      <br />
                      東京大学発サイバーセキュリティ企業という出自の通り東京大学出身・在籍のエンジニアが所属しており、さらに
                      **DEFCON Finals に出場するなど国内外の ***CTF
                      で実績を残しているエンジニアたちがツールを使った自動診断に併せて、社内で標準化された診断基準に知識と経験を活かした手動診断を行うことで網羅的に診断します。加えて、診断担当者とは別のエンジニアが再度診断のレビューを行うことで品質を高めます。
                    </p>
                    <p className="Feature_ContentText">
                      <span className="Feature_ContentTextSpan">
                        * OWASP : Open Wep Application Security Project
                        の略で、Webアプリケーションを始めとするソフトウェアのセキュリティ環境の現状や、安全なソフトウェア開発を促進する技術に関する情報共有と普及啓発を目的としたプロフェッショナルが集まる世界的なオープンソースソフトウェアコミュニティ
                        <br />
                        ** DEFCON : 世界最大の ***CTF の一つで、DEFCON Finals
                        はその大会の上位チームが進出できる決勝戦
                        <br />
                        *** CTF : Capture The
                        Flagの略で、旗取りゲームを意味しそこから派生してコンピュータセキュリティの技術を競う大会を指す
                      </span>
                    </p>
                  </div>
                  <div className="Feature_Content Others">
                    <h2 className="Feature_ContentTitle">
                      柔軟なコミュニケーション対応
                    </h2>
                    <p className="Feature_ContentText">
                      セキュリティ診断では専門知識の理解や実施にあたっての適切な環境の用意、アカウント・権限の割り当てなどが必要になり、円滑なコミュニケーションが肝要です。Flatt
                      Security
                      の診断ではお客様のご要望に応じてメールだけではなく Slack
                      でも連絡することが可能です。診断に関しての事前のご相談などは
                      Facebook Messenger での連絡も対応可能です。
                    </p>
                  </div>
                  <div className="Feature_Content Others">
                    <h2 className="Feature_ContentTitle">
                      オーダーメイドでの診断提案
                    </h2>
                    <p className="Feature_ContentText">
                      Flatt Security
                      のWebアプリケーション診断では、リクエスト数に応じてお見積もりを行います。(
                      <a className="Feature_Link" href="#estimate">
                        詳細
                      </a>
                      )
                      また、ご予算やスケジュールの事情にあわせて、リクエストごとの優先度付けを行い、柔軟に診断プランをご提案することも可能です。さらに、多岐にわたるご要望や、特殊な要件が含まれる場合でも、経験豊富なエンジニアの知識に基づき適切な診断プランをご提案いたします。
                    </p>
                  </div>
                  <div className="Feature_Content Others">
                    <h2 className="Feature_ContentTitle">
                      充実のアフターサービス
                      <br />
                      で診断後も安心
                    </h2>
                    <p className="Feature_ContentText">
                      セキュリティ診断の報告書の提出から1ヶ月後までは
                      *無償で再診断を行わせていただき、診断後の改修までサポートします。また、診断枠を事前にまとめて購入できるチケット制も提供可能なため、診断後の新機能の追加やアップデートなどの小規模の診断もご相談ください。
                      <div className="WebApp_Asterisk">
                        * 再診断をお受け致しかねる場合がございます。
                      </div>
                    </p>
                  </div>
                  <div className="Feature_Content Others">
                    <h2 className="Feature_ContentTitle">
                      わかりやすい報告書と
                      <br />
                      的確な対策提案
                    </h2>
                    <p className="Feature_ContentText">
                      診断結果は、ツールによる自動診断とエンジニアによる手動診断の結果を精査し、独自フォーマットの報告書にまとめて納品します。再現方法・対策方法・参考資料まで含めて丁寧に記載します。脆弱性の再現方法はお客様が脆弱性を再現しやすいように手順を詳細に記載します。さらに現場での開発経験豊富なエンジニアにより、わかりやすく的確な対策方法を提案させていただきます。
                    </p>
                  </div>
                  <div className="Feature_Content Sssmark">
                    <h2 className="Feature_ContentTitle">
                      経済産業省が定める基準に
                      <br className="Feature_Br" />
                      適合したサービス
                    </h2>
                    <div className="Feature_ContentSssmarkWrapper">
                      <p className="Feature_ContentTextSSS">
                        Flatt Security の
                        Webアプリケーション診断は、経済産業省が定める「情報セキュリティサービス基準」に適合したサービスとして登録されています。「情報セキュリティサービス基準」は、情報セキュリティサービスに関する一定の技術要件および品質管理要件を示し、品質の維持・向上に努めている情報セキュリティサービスを明らかにするために設けられました。
                      </p>
                      <div className="Feature_SSSmarkPictureWrapper">
                        <img
                          className="Feature_SSSmarkPicture"
                          src={data?.sssmark?.publicURL || ""}
                          alt="情報セキュリティサービスマーク"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="Webアプリケーション診断の事例インタビュー">
              <AssessmentVoiceList
                slugs={[
                  "tier4",
                  "finatext",
                  "liquid",
                  "ubie",
                  "mdm",
                  "smarthr",
                ]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <div className="Process">
              <div className="Process_Wrapper">
                <h2 className="Process_Heading">診断の手順</h2>
                <ul className="Process_StepList">
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">1</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断準備</h2>
                        <p className="Process_NoteMain">
                          NDA締結と事前ヒアリングを行い最適な診断プランをご提案します。対象のWebアプリケーションにも実際にアクセスし、お見積りを行います。お見積り内容に同意をいただけましたら契約を締結させていただきます。
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断対象のヒアリング
                            </h4>
                            <p className="Process_NoteNote">
                              弊社が診断対象の仕様を理解するために、ヒアリングを行います。仕様を理解することで、診断・ご提案の正確性の向上に繋がります。対象のサービス概要、規模感、環境、ご希望納期などをお伺いします。
                            </p>
                          </div>
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              お見積もり (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              環境、仕様書、設計書、画面遷移図、URL一覧などをご用意頂きます。こちらを元に対象のお見積り環境にアクセスし、リクエスト数や対象の調査をさせていただきます。お見積もりはそのリクエスト数をベースに算出します。設計書やルーティングをベースとした机上でのお見積もりも可能となっております。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">2</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断実施</h2>
                        <p className="Process_NoteMain">
                          お客様の方で診断環境をご用意していただき、対象を診断します。診断結果は診断完了後に報告書にまとめ納品しますが、特に緊急性の高い脆弱性が検出された場合には報告書納品前に簡易的な速報としてご連絡差し上げる事も可能です。
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断環境準備 (診断依頼企業様にて）
                            </h4>
                            <p className="Process_NoteNote">
                              貴社にて診断環境をご用意いただきます。ステージング環境やテスト環境など、本番同様の診断環境をご用意ください。
                            </p>
                          </div>
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断開始 (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              セキュリティエンジニアが専用のツールと手動での診断を組み合わせ、Webアプリケーションを網羅的に診断します。
                            </p>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">3</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断実施後</h2>
                        <p className="Process_NoteMain">
                          診断が完了いたしましたら診断結果をまとめた報告書をpdfにて納品させていただきます。是非改修にお役立てください。また、報告書をご納品いただいてから1ヶ月以内は再診断が*無料で実施可能です。
                          <div className="Process_Asterisk">
                            *無償での再診断は一定の条件がございます。
                          </div>
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              報告書納品 (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              報告書では診断した項目から対象のセキュリティ対策状況を5段階で評価します。また検出された脆弱性ごとに、達成した攻撃の再現方法、攻撃によるリスクおよび対策方法を詳しく記載します。
                            </p>
                          </div>
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              再診断 (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              報告書をご検収いただいてから1ヶ月以内は再診断を無償でご提供します。発見された脆弱性を診断依頼企業様にて改修していただいた後、実際に改修できているか弊社にて確認します。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoad>
          <Section>
            <Common2PaneSectionLayout width="large">
              <SubSection>
                <DocumentStyle>
                  <h2>診断項目</h2>
                  <p>
                    ツールによる自動診断とセキュリティエンジニアによる高度な手動診断を組み合わせたFlatt
                    Securityの脆弱性診断サービスです。OWASP Top 10 -
                    2017をベースの診断項目として最新のセキュリティリスクに対応した当社独自の診断項目を追加しています。
                  </p>
                </DocumentStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>インジェクション</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th>SQLインジェクション</th>
                        <td>
                          外部から与えられたパラメータを利用してSQL文を組み立てる場合に、組み立て方の不備によりデータベース内の非公開の情報が閲覧されてしまったり、情報が改ざんされてしまう脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>OSコマンドインジェクション</th>
                        <td>
                          Webアプリケーションからシェルを呼び出せる機能で、OSコマンドの組み立て方の不備により、外部から指定した任意のOSコマンドを実行できるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>HTTPヘッダインジェクション</th>
                        <td>
                          外部から与えられたパラメータを利用してHTTPレスポンスヘッダを生成する際に、生成方法の不備によりHTTPレスポンスヘッダに意図しない要素が追加されてしまう脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ファイルインクルージョン</th>
                        <td>
                          Webアプリケーションで読み込むファイルを外部から指定できる機能において、指定できるファイルの検証不備により意図しないファイルが読み込まれ情報漏洩が起きてしまう脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>コードインジェクション</th>
                        <td>
                          外部から受け取ったパラメータをeval関数などで実行する際に、意図せず悪意あるコードが実行されてしまう脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>XXEインジェクション</th>
                        <td>
                          外部から与えられたXMLを処理する機能において、外部実体参照を悪用してサーバ内のファイル情報の漏洩などにつながる脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>メールヘッダインジェクション</th>
                        <td>
                          外部から与えられたパラメータを利用してメールを送信する機能において、メールヘッダの生成方法の不備により不正にメールヘッダにフィールドを追加できるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>クロスサイトスクリプティング</th>
                        <td>
                          外部から受け取ったパラメータを利用してHTMLやJavaScriptを出力するWebページで、出力方法の不備により悪意あるJavaScriptを埋め込まれてしまうような脆弱性がないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>認証</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>ログインフォームの不備</th>
                        <td>
                          ログインフォームで、パスワード入力欄がマスクされているかどうか検査します。
                        </td>
                      </tr>

                      <tr>
                        <th>ログイン試行のエラーメッセージ</th>
                        <td>
                          ログイン失敗時に外部に表示されるメッセージに攻撃者にとって有益な情報が含まれていないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>アカウントロック機能の不備</th>
                        <td>
                          ログイン機能において、アカウントロックの仕組みにロックを回避できる問題などがないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ログアウト機能の不備</th>
                        <td>
                          ログイン機能を提供するサービスにおいて、ログアウト時に適切にセッションが破棄されるかどうか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>認証の回避</th>
                        <td>
                          パスワードの認証を回避してログインできるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>強制ブラウジング</th>
                        <td>
                          ログインしたアカウントでしか使用できない機能において、URLを直接指定してアクセスすることで認証をせずに使用できるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ユーザ登録フローの不備</th>
                        <td>
                          ユーザ登録フローに不備があり、アカウントの乗っ取りや個人情報の漏洩がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>
                          パスワード変更機能やパスワードリセット機能の不備
                        </th>
                        <td>
                          パスワード変更時に変更前のパスワード情報が入力が必要となっていることや、パスワードリセット時に再発行するまでの流れに問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>脆弱なパスワードポリシー</th>
                        <td>
                          第三者が容易に特定できるようなパスワードを設定できる問題がないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>認可</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>権限昇格</th>
                        <td>
                          管理者用機能のような権限を所有していないと操作できない機能において、一般ユーザの権限で実行できるような脆弱性がないか検査します。
                        </td>
                      </tr>

                      <tr>
                        <th>認可制御の不備</th>
                        <td>
                          情報の所有者のみアクセスできることを想定してしているような機能において、権限を持たないユーザが情報にアクセスできるような脆弱性がないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>セッション管理</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Cookieのsecure属性の欠如</th>
                        <td>
                          セッション管理をするCookieにおいてsecure属性が設定されているか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>セッションの有効期限</th>
                        <td>
                          セッションの有効期限が非常に長く設定されているような問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>セッションIDのランダム性確認</th>
                        <td>
                          セッションIDの生成方法において推測可能な問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>セッションフィクセーション</th>
                        <td>
                          外部からセッションIDを固定できるような問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>クロスサイトリクエストフォージェリ</th>
                        <td>
                          パスワードの変更機能や掲示板への書き込み機能などにおいて、利用者が意図しないリクエストが送信されてしまった場合に通常通り処理してしまう脆弱性がないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>一般的な脆弱性</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>既知のソフトウェア脆弱性</th>
                        <td>
                          使用しているソフトウェア（OSやライブラリなど）のバージョンが推測可能な場合に、使用しているバージョンに存在する既知の脆弱性の影響を受けないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ディレクトリリスティング</th>
                        <td>
                          ディレクトリ配下のファイルを一覧を表示する機能が意図せず公開されていないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>不要なHTTPメソッド</th>
                        <td>
                          Webサーバが不必要なHTTPメソッドを許容していないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>サーバーエラーメッセージ</th>
                        <td>
                          サーバのエラーメッセージに攻撃者に有益な情報が含まれていないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>システム情報の開示</th>
                        <td>
                          HTTPのレスポンスヘッダやシステムのエラー情報などから、使用しているシステムやそのバージョンのような情報が取得できないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>仕様や設計の不備や脆弱性</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>ロジックの不備</th>
                        <td>
                          特定の条件を満たさないと実行できない機能で条件を回避できる脆弱性や、システムの想定していないパラメータを処理してしまい攻撃者に有益な結果を与えてしまうような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>レースコンディション</th>
                        <td>
                          複数の処理で共有してアクセスする資源がある際に、トランザクション処理の不備などにより意図しない処理が行われてしまわないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>メール送信機能の悪用</th>
                        <td>
                          メールを送信する機能で、不正に送信先や送信内容を指定することで迷惑メールやフィッシングメールを送信できるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>キャッシュ制御の不備</th>
                        <td>
                          WebサーバやCDNのキャッシュの設定不備により、第三者に見られてはいけない情報がキャッシュされてしまう問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ディレクトリトラバーサル</th>
                        <td>
                          外部から与えられたパラメータで指定されたファイルにアクセスする機能において、想定していないファイルが指定されることでファイルの閲覧や改ざんが可能となるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>ファイルアップロードの不備</th>
                        <td>
                          ファイルをアップロードできる機能において、想定していない形式のファイルがアップロードされる問題やアップロードしたファイルの公開設定に不備がないかを検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>オープンリダイレクト</th>
                        <td>
                          外部から与えられたパラメータを利用してリダイレクト先を決める機能において、任意のリダイレクト先を指定できる脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>SSRF(サーバーサイドリクエストフォージェリ)</th>
                        <td>
                          サーバの機能を悪用して、本来アクセスすることができない内部リソースの情報を読み取ったり、改ざんできるような脆弱性がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>安全でないデシリアライゼーション</th>
                        <td>
                          外部からシリアライズされたデータを受け取ってデシリアライズする機能において、デシリアライズ時に任意コードが実行されてしまうような問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>平文による機微情報の送受信</th>
                        <td>
                          パスワードや個人情報などの機微な情報が、通信経路上暗号化されず平文で送受信されている問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>機微情報のGETパラメータでの受け渡し</th>
                        <td>
                          パスワードや個人情報などの機微な情報がGETパラメータでやり取りされることにより、意図せずアクセスログに出力されてしまう問題がないか検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <SubSection>
                <TableStyle>
                  <h3>クライアントサイドのセキュリティチェック</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>診断項目</th>
                        <th>診断方法</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Web Storageへの機微情報格納</th>
                        <td>
                          WebStorageに格納されている情報は容易にJavascriptからアクセス可能であるため、機微情報がWebStorageに含まれていないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>クリックジャッキング</th>
                        <td>
                          マウスやキーボード操作のみで使用可能な機能において、視覚的な細工をすることで利用者に意図しない操作をさせる攻撃が可能となる問題がないか検査します。
                        </td>
                      </tr>
                      <tr>
                        <th>不適切なCross-Origin Resource Sharingの利用</th>
                        <td>
                          不適切にCross-Origin Resource
                          Sharingのポリシーが設定されてしまっていることにより、アクセス制御に問題がないかを検査します。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableStyle>
              </SubSection>
              <DocumentStyle>
                <p>
                  より高度なセキュリティ診断をお求めの場合は100項目以上の診断を行うOWASP
                  ASVS準拠の診断やウェブペンテストのご提供も可能です。
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>

          <LazyLoad>
            <div className="Fee">
              <h2 id="estimate" className="Fee_Header">
                料金
              </h2>
              <div className="Fee_Wrapper wow fadeInUp">
                <h4 className="Fee_Title">Webアプリケーション診断</h4>
                <h2 className="Fee_Price">
                  30
                  <span className="Fee_SmallText">万円から</span>
                </h2>
              </div>
              <div className="Fee_Body body">
                リクエストに対して定められた単価をベースとして、お見積もり作業を通じて最終的なリクエスト数・料金を決定します。
              </div>
              <div className="Fee_RequestWrapper">
                <h2 className="Fee_RequestH2">リクエストとは？</h2>
                <p className="Fee_RequestNote note">
                  ボタンを押してページが変わったり、入力が確定するなどしてサーバーとの通信が発生することをリクエストと言います。私たちは、リクエスト数、つまりサイト内で通信が起こりうる箇所の数を基準にお見積もりしています。お見積もりは無料でさせていただきますが、リクエスト数＝画面遷移数で計算しても概算が可能です。
                </p>
                <ul className="Fee_RequestExampleWrapper">
                  <li className="Fee_RequestExample wow fadeInUp">
                    <h5 className="Fee_RequestH5">
                      例1.
                      <span className="Fee_RequestPrimary">画面遷移する際</span>
                    </h5>
                    <div className="Fee_RequestImageWrapper">
                      <img
                        className="Fee_RequestImage"
                        src={data?.request_1?.publicURL || ""}
                        alt=""
                      />
                    </div>
                    <p className="Fee_RequestExampleNote note">
                      基本的に、ページ遷移のたびにリクエストが発生するので大まかなリクエスト数は計算することができます
                    </p>
                  </li>
                  <li className="Fee_RequestExample wow fadeInUp">
                    <h5 className="Fee_RequestH5">
                      例2.
                      <span className="Fee_RequestPrimary">
                        画面遷移はしないが通信結果を表示する際
                      </span>
                    </h5>
                    <div className="Fee_RequestImageWrapper">
                      <img
                        className="Fee_RequestImage"
                        src={data?.request_2?.publicURL || ""}
                        alt=""
                      />
                    </div>
                    <p className="Fee_RequestExampleNote note">
                      コメントや検索など、一見ページ遷移が行われていなくてもリクエストが発生するパターンもあります
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoad>
          <AssessmentDocContact />

          <div className="WhiteboxLink">
            <div className="WhiteboxLink_Wrapper">
              <h2 className="WhiteboxLink_SubTitle">
                FirebaseやGraphQL採用のアプリケーションにも対応
              </h2>
              <h2 className="WhiteboxLink_Title">
                ホワイトボックス診断プランもご利用可能です
              </h2>
              <div className="WhiteboxLink_ContentsWrapper">
                <div className="WhiteboxLink_ImageWrapper">
                  <img
                    className="WhiteboxLink_FirebaseGraphQL"
                    src={data?.FirebaseGraphQL_logo?.publicURL || ""}
                    alt="FirebaseGrapfQL"
                  />
                </div>
                <p className="WhiteboxLink_Content">
                  設計や仕様、設定、ソースコードまで包括的にチェックしたい場合や診断対象がモダンな技術スタックを用いたアプリケーションの場合は、ホワイトボックス診断プランでより効果的な診断の実施が可能です。
                </p>
              </div>
              <Link
                to="/assessment/plan/whitebox/"
                className="WhiteboxLink_Button button"
              >
                <Button buttonTheme="Default" buttonSize="Medium">
                  詳細はこちら
                </Button>
              </Link>
            </div>
          </div>

          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />

          <LazyLoad>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <FaqStyle>
                  <h2>よくある質問</h2>
                  <div>
                    <h3>
                      社内の人間しか使用しないシステムでも診断の必要はあるのですか？
                    </h3>
                    <p>
                      確かに外部に公開されているシステムに比べると攻撃される可能性は下がりますが、アクセス制限の不備やそもそものソフトウェアの脆弱性などにより社外からシステムのデータにアクセスできる場合もあります。センシティブな情報を扱うことの多い社内システムだからこそ診断は必要と言えるでしょう。
                    </p>
                  </div>
                  <div>
                    <h3>全ての画面を診断する必要はあるのですか？</h3>
                    <p>
                      特に脆弱性の潜む要素のないような画面であっても実際に診断してみるまでは、脆弱なパラメタが含まれているなどの危険性を判別できないことが多いです。なので弊社ではひとつサービスを診断するとなれば全画面の診断を原則としています。しかしご予算の関係上どうしても厳しいという場合はご予算に合わせて診断内容の調整も可能ですので、ご相談ください。
                    </p>
                  </div>
                  <div>
                    <h3>Flatt Securityの診断の強みは何ですか？</h3>
                    <p>
                      専用診断ツールによる自動診断とセキュリティエンジニアによる手動診断を組み合わせて網羅的に診断をすることを強みとしています。ツールと手動にはそれぞれ強みがありますが、併用することでより精度の高い診断を実施することが可能です。
                    </p>
                  </div>
                  <div>
                    <h3>
                      自分の運営しているサービスは脆弱性診断が必要なのかわかりません。
                    </h3>
                    <p>
                      お見積もりは無料ですので、是非お気軽にお問い合わせください。ヒアリングを通してベストなご提案をさせていただきます。
                    </p>
                  </div>
                </FaqStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
          <h2 className="AssessmentCommonContact_Title">
            お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
          </h2>
          <Link to="/contact">
            <div className="AssessmentCommonQuickContact">
              <Button buttonTheme="Secondary" buttonSize="Large">
                今すぐお問い合わせ
              </Button>
            </div>
          </Link>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query AssessmentWebAppPage {
    checkbox: file(relativePath: { eq: "checkbox.png" }) {
      publicURL
    }
    document: file(relativePath: { eq: "document.png" }) {
      publicURL
    }
    request_1: file(relativePath: { eq: "request_1.png" }) {
      publicURL
    }
    request_2: file(relativePath: { eq: "request_2.png" }) {
      publicURL
    }
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    sssmark: file(relativePath: { eq: "sssmark.jpg" }) {
      publicURL
    }
    FirebaseGraphQL_logo: file(
      relativePath: { eq: "FirebaseGraphQL_logo.png" }
    ) {
      publicURL
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "web_app" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "web_app" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(LP);
